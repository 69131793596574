.main {
    min-width: 100%;
    max-height: 770px;
    font-family: 'Playfair Display';
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.navBar {
    width: 100%;
    background-color: #981422;
    display: flex;
    flex-direction: row;
    height: 100px;
}

.navBar-links {
    display: flex;
    flex-direction: row;
    min-width: 80%;
    justify-content: space-evenly;
    line-height: 65px;
    font-weight: bold;
}

.navBar-page {
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.logo {
    width: 100px;
    margin-left: 50px;
}

.side-menu {
    display: none;
    margin-top: 30px;
    cursor: pointer;
}

.main-div {
    width: 85%;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    z-index: 1000;
    margin-top: 30px;
}

.main-div-left {
    max-width: 45%;
}

#street-image {
    width: 100%;
    height: auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.main-div-right {
    width: 55%;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#title {
    font-weight: bold;
    color: black;
    font-size: 4vw;
    width: 70%;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 0;
}

#subtitle {
    font-weight: bold;
    color: #981422;
    font-size: 1.5vw;
    text-align: center;
    width: 85%;
    margin-top: 0;
}

.icon-text {
    display: flex;
    flex-direction: row;
    margin-top: -10px;
    line-height: 20px;
}

#icon {
    font-size: 35px;
    margin-top: 8px;
    margin-right: 10px;
}

#text {
    font-size: 1.2vw;
    font-weight: bold;
}

.bottom-right {
    width: 45%;
    margin-right: 7.5%;
    float: right;
}

.bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 60px;
    cursor: pointer;
}

#button-one {
    background-color: #981422;
    border: 3px solid #000000;
    border-radius: 10px;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2vw;
    font-weight: bold;
    cursor: pointer;
    flex-direction: row;
    margin-right: 30px;
    color: white;
}

#button-two {
    background-color: #981422;
    border: 3px solid #000000;
    border-radius: 10px;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2vw;
    font-weight: bold;
    cursor: pointer;
    flex-direction: row;
}

#button-icon {
    font-size: 35px;
}

@media (max-width: 850px) {
    .logo {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .side-menu {
        display: inline;
        margin-left: 30px;
    }

    .navBar-links {
        display: none;
    }

    .navBar {
        width: 100%;
        background-color: #981422;
        display: flex;
        flex-direction: row;
        min-height: 100px;
    }

}

@media (max-width: 1250px) {
    .main-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        z-index: 1000;
        margin-top: 30px;
        justify-content: center;
        align-items: center;
    }

    .main-div-left {
        max-width: 100%;
        text-align: center;

    }

    #street-image {
        width: 50%;
        height: auto;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    .main-div-right {
        width: 100%;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #title {
        font-size: 30px;
        width: 70%;
    }

    #subtitle {
        font-weight: bold;
        color: #981422;
        font-size: 15px;
        text-align: center;
        width: 85%;
        margin-top: 0;
    }

    .icon-text {
        display: flex;
        flex-direction: row;
        margin-top: -10px;
        line-height: 20px;
    }

    #icon {
        font-size: 35px;
        margin-top: 8px;
        margin-right: 10px;
    }

    #text {
        font-size: 13px;
        font-weight: bold;
    }

    #button-one {
        font-size: 13px;
    }

    #button-icon {
        font-size: 25px;
    }

    .bottom-buttons {
        margin-top: 20px;
        margin-bottom: 30px;
    }

}

@media (max-width: 500px) {
    .bottom-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        width: 100%;
        margin-top: 20px;
    }

    #button-one {
        margin-right: 0;
        margin-bottom: 10px;
        width: 50%;
        height: 40px;
    }

    #button-two {
        width: 50%;
        height: 40px;
    }
}